body {
    font-family: var(--font-family);

    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
}

app-root {
    display: block;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
}

/* You can add global styles to this file, and also import other style files */
html, body {
  margin: 0;
  padding: 0;

//  height: 100%;
//  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

:root {
    --header-1-h: 54px;
    --content-1-h: calc(100% - var(--header-1-h));
}

.header-1-height {
    height: var(--header-1-h);
    min-height: var(--header-1-h);
    max-height: var(--header-1-h);
}


a:link {
    text-decoration: inherit;
    color: var(--indigo-600);
}

a:visited {
    text-decoration: inherit;
    color: var(--indigo-600);
}

/* CAN (MUST) BE REMOVED ! Sample Global style */
// .container {
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   background: url(./assets/background.jpg) no-repeat center fixed;
//   -webkit-background-size: cover; /* pour anciens Chrome et Safari */
//   background-size: cover; /* version standardisée */

//   .title {
//     color: white;
//     margin: 0;
//     padding: 50px 20px;
//   }

//   a {
//     color: #fff !important;
//     text-transform: uppercase;
//     text-decoration: none;
//     background: #ed3330;
//     padding: 20px;
//     border-radius: 5px;
//     display: inline-block;
//     border: none;
//     transition: all 0.4s ease 0s;

//     &:hover {
//       background: #fff;
//       color: #ed3330 !important;
//       letter-spacing: 1px;
//       -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
//       -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
//       box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
//       transition: all 0.4s ease 0s;
//     }
//   }
// }


.h-60vh {
  height: 60vh !important;
}
.h-80vh {
  height: 80vh !important;
}
.table-cell {
    display: table-cell !important;
}
.w-35rem {
    width: 35rem !important;
}
.w-40rem {
    width: 40rem !important;
}
@media screen and (min-width: 576px) {
    .sm\:h-80vh {
        height: 80vh !important;
    }
    .sm\:h-60vh {
        height: 80vh !important;
    }
    .sm\:table-cell {
        display: table-cell !important;
    }
    .sm\:w-35rem {
        width: 35rem !important;
    }
    .sm\:w-40rem {
        width: 40rem !important;
    }
}
@media screen and (min-width: 768px) {
    .md\:h-80vh {
        height: 80vh !important;
    }
    .md\:table-cell {
        display: table-cell !important;
    }
    .md\:w-35rem {
        width: 35rem !important;
    }
    .md\:w-40rem {
        width: 40rem !important;
    }
}
@media screen and (min-width: 992px) {
    .lg\:h-80vh {
        height: 80vh !important;
    }
    .lg\:table-cell {
        display: table-cell !important;
    }
    .lg\:w-35rem {
        width: 35rem !important;
    }
    .lg\:w-40rem {
        width: 40rem !important;
    }
}
@media screen and (min-width: 1200px) {
    .xl\:h-80vh {
        height: 80vh !important;
    }
    .xl\:table-cell {
        display: table-cell !important;
    }
    .xl\:w-35rem {
        width: 35rem !important;
    }
    .xl\:w-40rem {
        width: 40rem !important;
    }
}
